
import { Component, Mixins } from 'vue-property-decorator';
import TextInputComponent from '@/components/Forms/Elements/TextInputComponent.vue';
import DropdownComponent from '@/components/Forms/Elements/DropdownComponent.vue';
import DateSelectorComponent from '@/components/Forms/Elements/DateSelectorComponent.vue';
import RadioComponent from '@/components/Forms/Elements/RadioComponent.vue';
import PhoneInputComponent from '@/components/Forms/Elements/PhoneInputComponent.vue';
import { DropdownOptions, RadioOptions } from '@/models/Forms/FormOption';
import {
  EArb,
  EDutyPhoneTypes,
  EPriorServiceComponent,
  EServiceGrades
} from '@/enums';
import { getInputOptions } from '@/util/getInputOptions';
import ApplicationSectionMixin from '@/mixins/ApplicationSectionMixin';
import { APPLICATION_HELP_TEXT } from '@/models/Entities/Application/Constants';

@Component<ApplicationFormPriorServiceSection>({
  components: {
    DateSelectorComponent,
    DropdownComponent,
    PhoneInputComponent,
    RadioComponent,
    TextInputComponent
  }
})
export default class ApplicationFormPriorServiceSection extends Mixins(
  ApplicationSectionMixin
) {
  priorServiceComponents: DropdownOptions[] = getInputOptions(
    EPriorServiceComponent
  );
  arbOptions = getInputOptions(EArb);
  serviceGrades: DropdownOptions[] = getInputOptions(EServiceGrades);
  dutyPhoneRadioOptions: RadioOptions[] = getInputOptions(EDutyPhoneTypes);
  hoverText = APPLICATION_HELP_TEXT;
}

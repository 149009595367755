var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    { attrs: { id: _vm.SectionLabels.BASE_PREFERENCES.key } },
    [
      _c("h3", { staticClass: "section-header" }, [_vm._v("Base Preferences")]),
      _c(
        "div",
        { staticClass: "my-4 form-input-container" },
        [
          _c("dropdown-component", {
            attrs: {
              id: _vm.FieldItemLabels.BASE_PREFERENCE.key,
              label: "First Preference",
              "data-cy": "preferredUptBase1Selector",
              required: _vm.application.requirePreferredUptBases,
              errorText: "Please enter a preferred UPT base",
              values: _vm.uptBaseDropdownOptions,
              hideDisabledOptions: true,
              placeholder: "Select one",
            },
            on: {
              validationState: function ($event) {
                return _vm.$emit("validationState", $event)
              },
            },
            model: {
              value: _vm.application.selectionPreferencesBasepreference1,
              callback: function ($$v) {
                _vm.$set(
                  _vm.application,
                  "selectionPreferencesBasepreference1",
                  $$v
                )
              },
              expression: "application.selectionPreferencesBasepreference1",
            },
          }),
          _c("dropdown-component", {
            attrs: {
              "data-cy": "preferredUptBase2Selector",
              errorText: "Please enter a preferred UPT base",
              values: _vm.uptBaseDropdownOptions,
              label: "Second preference",
              hideDisabledOptions: true,
              placeholder: "Select one",
            },
            on: {
              validationState: function ($event) {
                return _vm.$emit("validationState", $event)
              },
            },
            model: {
              value: _vm.application.selectionPreferencesBasepreference2,
              callback: function ($$v) {
                _vm.$set(
                  _vm.application,
                  "selectionPreferencesBasepreference2",
                  $$v
                )
              },
              expression: "application.selectionPreferencesBasepreference2",
            },
          }),
          _c("dropdown-component", {
            attrs: {
              "data-cy": "preferredUptBase3Selector",
              errorText: "Please enter a preferred UPT base",
              values: _vm.uptBaseDropdownOptions,
              label: "Third preference",
              hideDisabledOptions: true,
              placeholder: "Select one",
            },
            on: {
              validationState: function ($event) {
                return _vm.$emit("validationState", $event)
              },
            },
            model: {
              value: _vm.application.selectionPreferencesBasepreference3,
              callback: function ($$v) {
                _vm.$set(
                  _vm.application,
                  "selectionPreferencesBasepreference3",
                  $$v
                )
              },
              expression: "application.selectionPreferencesBasepreference3",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
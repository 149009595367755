var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    { attrs: { id: _vm.SectionLabels.TRAINING_PREFERENCE.key } },
    [
      _c("h3", { staticClass: "section-header" }, [
        _vm._v("Training Preferences"),
      ]),
      _c(
        "div",
        { staticClass: "mb-10 form-input-container gap-y-5" },
        [
          _c("dropdown-component", {
            attrs: {
              id: _vm.FieldItemLabels.TRAINING_PREFERENCE.key,
              "data-cy": "desiredTraining1Selector",
              errorText:
                "Please choose AT LEAST ONE choice from the list. DO NOT SELECT a choice for which you would NOT ACCEPT the training, if selected. (If you do not want a specific training, do not mark it.)",
              placeholder: "Select one",
              label: "First Preference",
              values: _vm.desiredTrainingTypes,
              hideDisabledOptions: true,
            },
            on: {
              validationState: function ($event) {
                return _vm.$emit("validationState", $event)
              },
            },
            model: {
              value: _vm.application.selectionPreferencesTrainingpreference1,
              callback: function ($$v) {
                _vm.$set(
                  _vm.application,
                  "selectionPreferencesTrainingpreference1",
                  $$v
                )
              },
              expression: "application.selectionPreferencesTrainingpreference1",
            },
          }),
          _c("dropdown-component", {
            attrs: {
              "data-cy": "desiredTraining2Selector",
              errorText: "Please select desired training",
              placeholder: "Select one",
              label: "Second preference",
              values: _vm.desiredTrainingTypes,
              hideDisabledOptions: true,
            },
            on: {
              validationState: function ($event) {
                return _vm.$emit("validationState", $event)
              },
            },
            model: {
              value: _vm.application.selectionPreferencesTrainingpreference2,
              callback: function ($$v) {
                _vm.$set(
                  _vm.application,
                  "selectionPreferencesTrainingpreference2",
                  $$v
                )
              },
              expression: "application.selectionPreferencesTrainingpreference2",
            },
          }),
          _c("dropdown-component", {
            attrs: {
              "data-cy": "desiredTraining3Selector",
              errorText: "Please select desired training",
              placeholder: "Select one",
              label: "Third preference",
              values: _vm.desiredTrainingTypes,
              hideDisabledOptions: true,
            },
            on: {
              validationState: function ($event) {
                return _vm.$emit("validationState", $event)
              },
            },
            model: {
              value: _vm.application.selectionPreferencesTrainingpreference3,
              callback: function ($$v) {
                _vm.$set(
                  _vm.application,
                  "selectionPreferencesTrainingpreference3",
                  $$v
                )
              },
              expression: "application.selectionPreferencesTrainingpreference3",
            },
          }),
          _c("dropdown-component", {
            attrs: {
              "data-cy": "desiredTraining4Selector",
              errorText: "Please select desired training",
              placeholder: "Select one",
              label: "Fourth preference",
              values: _vm.desiredTrainingTypes,
              hideDisabledOptions: true,
            },
            on: {
              validationState: function ($event) {
                return _vm.$emit("validationState", $event)
              },
            },
            model: {
              value: _vm.application.selectionPreferencesTrainingpreference4,
              callback: function ($$v) {
                _vm.$set(
                  _vm.application,
                  "selectionPreferencesTrainingpreference4",
                  $$v
                )
              },
              expression: "application.selectionPreferencesTrainingpreference4",
            },
          }),
          _c("date-selector-component", {
            attrs: {
              id: _vm.FieldItemLabels.AVAILABLE_TRAINING_DATE.key,
              label: _vm.FieldItemLabels.AVAILABLE_TRAINING_DATE.label,
              "data-cy": "availableTrainingDateSelector",
              errorText: "Please choose the date available to start training",
            },
            on: {
              validationState: function ($event) {
                return _vm.$emit("validationState", $event)
              },
            },
            model: {
              value: _vm.application.availableTrainingDate,
              callback: function ($$v) {
                _vm.$set(_vm.application, "availableTrainingDate", $$v)
              },
              expression: "application.availableTrainingDate",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", { attrs: { id: _vm.SectionLabels.PRIOR_SERVICE.key } }, [
    _c("h3", { staticClass: "section-header" }, [_vm._v("Prior Service")]),
    _c(
      "div",
      { staticClass: "form-input-container" },
      [
        _c("dropdown-component", {
          attrs: {
            id: _vm.FieldItemLabels.PRIOR_SERVICE_COMPONENT.key,
            label: _vm.FieldItemLabels.PRIOR_SERVICE_COMPONENT.label,
            "data-cy": "priorServiceComponentSelector",
            required: _vm.application.isActiveDuty,
            errorText: "Please select a component",
            placeholder: "Select one",
            values: _vm.priorServiceComponents,
          },
          on: {
            validationState: function ($event) {
              return _vm.$emit("validationState", $event)
            },
          },
          model: {
            value: _vm.application.priorServiceComponent,
            callback: function ($$v) {
              _vm.$set(_vm.application, "priorServiceComponent", $$v)
            },
            expression: "application.priorServiceComponent",
          },
        }),
        _c("dropdown-component", {
          attrs: {
            id: _vm.FieldItemLabels.PRIOR_SERVICE_GRADE.key,
            label: _vm.FieldItemLabels.PRIOR_SERVICE_GRADE.label,
            "data-cy": "priorServiceGradeSelector",
            required: _vm.application.isActiveDuty,
            errorText: "You must enter a grade",
            placeholder: "Select one",
            values: _vm.serviceGrades,
          },
          on: {
            validationState: function ($event) {
              return _vm.$emit("validationState", $event)
            },
          },
          model: {
            value: _vm.application.priorServiceGrade,
            callback: function ($$v) {
              _vm.$set(_vm.application, "priorServiceGrade", $$v)
            },
            expression: "application.priorServiceGrade",
          },
        }),
        _c("dropdown-component", {
          attrs: {
            helpText: _vm.hoverText.priorService.arb,
            id: _vm.FieldItemLabels.PRIOR_SERVICE_ARB.key,
            label: _vm.FieldItemLabels.PRIOR_SERVICE_ARB.label,
            "data-cy": "priorServiceArbSelector",
            required: _vm.application.isActiveDuty,
            errorText: "Please select a component",
            placeholder: "Select one",
            values: _vm.arbOptions,
          },
          on: {
            validationState: function ($event) {
              return _vm.$emit("validationState", $event)
            },
          },
          model: {
            value: _vm.application.priorServiceArb,
            callback: function ($$v) {
              _vm.$set(_vm.application, "priorServiceArb", $$v)
            },
            expression: "application.priorServiceArb",
          },
        }),
        _c("date-selector-component", {
          attrs: {
            id: _vm.FieldItemLabels.PRIOR_SERVICE_DATE_OF_RANK.key,
            label: _vm.FieldItemLabels.PRIOR_SERVICE_DATE_OF_RANK.label,
            "data-cy": "priorServiceDateOfRankSelector",
            required: _vm.application.isActiveDuty,
            errorText:
              "Please select a valid Date of Rank for the highest grade you have currently, or you previously held.",
          },
          on: {
            validationState: function ($event) {
              return _vm.$emit("validationState", $event)
            },
          },
          model: {
            value: _vm.application.priorServiceDateofrank,
            callback: function ($$v) {
              _vm.$set(_vm.application, "priorServiceDateofrank", $$v)
            },
            expression: "application.priorServiceDateofrank",
          },
        }),
        _c("text-input-component", {
          attrs: {
            id: _vm.FieldItemLabels.PRIOR_SERVICE_TOTAL_FLIGHT_HOURS.key,
            label: _vm.FieldItemLabels.PRIOR_SERVICE_TOTAL_FLIGHT_HOURS.label,
            "data-cy": "flyExperienceMilitaryTotalFlightHoursInput",
            type: "number",
            maxlength: "8",
            regex: _vm.Regex.number,
          },
          on: {
            validationState: function ($event) {
              return _vm.$emit("validationState", $event)
            },
          },
          model: {
            value: _vm.application.flyExperienceTotalmilitaryflighthours,
            callback: function ($$v) {
              _vm.$set(
                _vm.application,
                "flyExperienceTotalmilitaryflighthours",
                $$v
              )
            },
            expression: "application.flyExperienceTotalmilitaryflighthours",
          },
        }),
        _c("text-input-component", {
          attrs: {
            id: _vm.FieldItemLabels.PRIOR_SERVICE_AIRFRAME.key,
            label: _vm.FieldItemLabels.PRIOR_SERVICE_AIRFRAME.label,
            "data-cy": "priorServiceAirframeInput",
          },
          on: {
            validationState: function ($event) {
              return _vm.$emit("validationState", $event)
            },
          },
          model: {
            value: _vm.application.priorServiceAirframe,
            callback: function ($$v) {
              _vm.$set(_vm.application, "priorServiceAirframe", $$v)
            },
            expression: "application.priorServiceAirframe",
          },
        }),
        _c("date-selector-component", {
          attrs: {
            id: _vm.FieldItemLabels.PRIOR_SERVICE_COMMISSION_DATE.key,
            label: _vm.FieldItemLabels.PRIOR_SERVICE_COMMISSION_DATE.label,
            "data-cy": "priorServiceCommissionServiceDateSelector",
            errorText: "Please select a commission service date",
          },
          on: {
            validationState: function ($event) {
              return _vm.$emit("validationState", $event)
            },
          },
          model: {
            value: _vm.application.priorServiceCommissionservicedate,
            callback: function ($$v) {
              _vm.$set(
                _vm.application,
                "priorServiceCommissionservicedate",
                $$v
              )
            },
            expression: "application.priorServiceCommissionservicedate",
          },
        }),
        _c("text-input-component", {
          attrs: {
            id: _vm.FieldItemLabels.PRIOR_SERVICE_DUTY_TITLE.key,
            label: _vm.FieldItemLabels.PRIOR_SERVICE_DUTY_TITLE.label,
            "data-cy": "priorServiceDutyTitleInput",
            required: _vm.application.isActiveDuty,
            errorText:
              "Please enter the Duty Title for your current military position, or your last position in your previous military service.",
          },
          on: {
            validationState: function ($event) {
              return _vm.$emit("validationState", $event)
            },
          },
          model: {
            value: _vm.application.priorServiceDutytitle,
            callback: function ($$v) {
              _vm.$set(_vm.application, "priorServiceDutytitle", $$v)
            },
            expression: "application.priorServiceDutytitle",
          },
        }),
        _c("text-input-component", {
          attrs: {
            id: _vm.FieldItemLabels.PRIOR_SERVICE_DUTY_STATION.key,
            label: _vm.FieldItemLabels.PRIOR_SERVICE_DUTY_STATION.label,
            "data-cy": "priorServiceDutyStationInput",
            required: _vm.application.isActiveDuty,
            errorText:
              "Please enter the Unit Designator and the Duty Station (Installation) of your present duty station (if actively serving), or your last duty station (if previously served, but not currently serving).",
          },
          on: {
            validationState: function ($event) {
              return _vm.$emit("validationState", $event)
            },
          },
          model: {
            value: _vm.application.priorServiceDutystation,
            callback: function ($$v) {
              _vm.$set(_vm.application, "priorServiceDutystation", $$v)
            },
            expression: "application.priorServiceDutystation",
          },
        }),
        _c("radio-component", {
          attrs: {
            id: _vm.FieldItemLabels.PRIOR_SERVICE_DUTY_PHONE_TYPE.key,
            label: _vm.FieldItemLabels.PRIOR_SERVICE_DUTY_PHONE_TYPE.label,
            "data-cy": "dutyPhoneTypeSelector",
            required: _vm.application.isActiveDuty,
            errorText: "Please indicate the duty phone type, DSM or COMM.",
            radioItems: _vm.dutyPhoneRadioOptions,
          },
          on: {
            validationState: function ($event) {
              return _vm.$emit("validationState", $event)
            },
          },
          model: {
            value: _vm.application.priorServiceDutyphonetype,
            callback: function ($$v) {
              _vm.$set(_vm.application, "priorServiceDutyphonetype", $$v)
            },
            expression: "application.priorServiceDutyphonetype",
          },
        }),
        _c("phone-input-component", {
          attrs: {
            id: _vm.FieldItemLabels.PRIOR_SERVICE_DUTY_PHONE.key,
            label: "Phone Number",
            "data-cy": "priorServiceDutyPhoneInput",
            required: _vm.application.isActiveDuty,
            errorText:
              "If you are currently serving in the military (any branch), please indicate your Duty Phone number.\nUse the radio button to indicate whether the number you provided is DSN (with exchange) or Commercial",
            placeholder: "Enter phone",
          },
          on: {
            validationState: function ($event) {
              return _vm.$emit("validationState", $event)
            },
          },
          model: {
            value: _vm.application.priorServiceDutyphone,
            callback: function ($$v) {
              _vm.$set(_vm.application, "priorServiceDutyphone", $$v)
            },
            expression: "application.priorServiceDutyphone",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }

import { Component, Mixins } from 'vue-property-decorator';
import DropdownComponent from '@/components/Forms/Elements/DropdownComponent.vue';
import DateSelectorComponent from '@/components/Forms/Elements/DateSelectorComponent.vue';
import { DropdownOptions } from '@/models/Forms/FormOption';
import { getInputOptions } from '@/util/getInputOptions';
import { BadgeType, EPreferredUptBaseTypes } from '@/enums';
import ApplicationSectionMixin from '@/mixins/ApplicationSectionMixin';

@Component<ApplicationFormTrainingPreferencesSection>({
  components: {
    DateSelectorComponent,
    DropdownComponent
  }
})
export default class ApplicationFormTrainingPreferencesSection extends Mixins(
  ApplicationSectionMixin
) {
  uptBaseDropdownOptions = getInputOptions(EPreferredUptBaseTypes);

  get desiredTrainingTypes(): DropdownOptions[] {
    const enumValues = Object.values(BadgeType);
    return enumValues.map<DropdownOptions>((item) => {
      const disabled = this.application.currentDesiredTrainings?.includes(item);
      return {
        label: item,
        value: item,
        disabled
      };
    });
  }
}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "z-10 items-center w-full p-4 bg-white border border-gray-300 rounded shadow-2xl",
    },
    [
      _c("div", { staticClass: "flex flex-col items-center" }, [
        _c("div", { staticClass: "text-2xl font-semibold" }, [
          _vm._v("Board: " + _vm._s(_vm.boardTitle)),
        ]),
        _c("div", {}, [
          _vm._v(
            " All sections must be complete in order to submit this application to a board. "
          ),
        ]),
        _c(
          "div",
          { staticClass: "flex flex-wrap justify-center gap-2 mt-2 mb-4" },
          [
            _c(
              "button-component",
              {
                staticClass:
                  "flex items-center justify-center w-full md:w-auto",
                class: { "opacity-80": _vm.updateButtonDisabled },
                attrs: {
                  bg: "val-button-blue",
                  textOrBorderColor: "white",
                  disabled: _vm.updateButtonDisabled,
                },
                on: { click: _vm.update },
              },
              [
                _c("span", [_vm._v("Update Application")]),
                _vm.isUpdatingApplication
                  ? _c("icon-component", {
                      staticClass: "ml-2 transform rotate-180 animate-spin",
                      attrs: { name: "loading", width: 20, height: 20 },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "button-component",
              {
                staticClass:
                  "flex items-center justify-center w-full md:w-auto",
                class: { "opacity-80": _vm.isComplete },
                attrs: {
                  bg: _vm.isComplete ? "gray-500" : "val-button-blue",
                  textOrBorderColor: "white",
                  disabled: _vm.isComplete,
                },
                on: { click: _vm.toggleRequiredFields },
              },
              [
                _c("span", { staticClass: "pr-2 text-xl font-semibold" }, [
                  _vm._v(_vm._s(_vm.application.incompleteFields.length)),
                ]),
                _c("span", { staticClass: "pr-2" }, [
                  _vm._v("Incomplete Sections"),
                ]),
                _c("icon-component", {
                  staticClass: "transform fill-current",
                  class: _vm.showSections ? "rotate-180" : "rotate-0",
                  attrs: { height: 24, width: 24, name: "chevronDown" },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm.showSections
          ? _c(
              "div",
              {
                staticClass:
                  "flex flex-wrap justify-between w-full gap-8 px-4 py-2",
              },
              _vm._l(
                _vm.incompleteSections,
                function (incompleteSection, sectionName) {
                  return _c("div", { key: sectionName }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "text-lg font-semibold uppercase whitespace-nowrap",
                        on: {
                          click: function ($event) {
                            return _vm.scrollToElement(
                              incompleteSection.section.key
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(incompleteSection.section.label) + " "
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "flex flex-col" },
                      _vm._l(
                        incompleteSection.items,
                        function (fieldItem, index) {
                          return _c(
                            "button",
                            {
                              key: index,
                              staticClass:
                                "text-left text-blue-500 capitalize hover:underline",
                              on: {
                                click: function ($event) {
                                  return _vm.scrollToElement(
                                    fieldItem.field.key
                                  )
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(fieldItem.field.label) + " ")]
                          )
                        }
                      ),
                      0
                    ),
                  ])
                }
              ),
              0
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
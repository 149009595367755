var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", { attrs: { id: _vm.SectionLabels.ENDORSEMENTS.key } }, [
    _c("h3", { staticClass: "section-header" }, [_vm._v("Endorsements")]),
    _c(
      "div",
      { staticClass: "form-input-container" },
      [
        _c("dropdown-component", {
          attrs: {
            id: _vm.FieldItemLabels.FLIGHT_PHYSICAL_STATUS.key,
            label: _vm.FieldItemLabels.FLIGHT_PHYSICAL_STATUS.label,
            "data-cy": "flightPhysicalStatusAtBoardSelector",
            errorText: "Please choose AT LEAST ONE choice from the list.",
            placeholder: "Select one",
            values: _vm.flightPhysicalTypes,
          },
          on: {
            validationState: function ($event) {
              return _vm.$emit("validationState", $event)
            },
          },
          model: {
            value: _vm.application.flightPhysicalStatusAtBoard,
            callback: function ($$v) {
              _vm.$set(_vm.application, "flightPhysicalStatusAtBoard", $$v)
            },
            expression: "application.flightPhysicalStatusAtBoard",
          },
        }),
        _c("dropdown-component", {
          attrs: {
            id: _vm.FieldItemLabels.SCROLL_STATUS.key,
            label: _vm.FieldItemLabels.SCROLL_STATUS.label,
            "data-cy": "scrollStatusSelector",
            errorText: "Please choose AT LEAST ONE choice from the list.",
            placeholder: "Select one",
            values: _vm.scrollStatusTypes,
          },
          on: {
            validationState: function ($event) {
              return _vm.$emit("validationState", $event)
            },
          },
          model: {
            value: _vm.application.scrollStatus,
            callback: function ($$v) {
              _vm.$set(_vm.application, "scrollStatus", $$v)
            },
            expression: "application.scrollStatus",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
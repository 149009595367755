var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    { attrs: { id: _vm.SectionLabels.APPLICANT_REMARKS.key } },
    [
      _c("h3", { staticClass: "text-base section-header" }, [
        _vm._v("Applicant Remarks"),
      ]),
      _c("text-area-component", {
        attrs: {
          "data-cy": "applicantRemarksInput",
          label: "Enter Remarks here:",
          maxLength: 500,
        },
        model: {
          value: _vm.application.applicantRemarks,
          callback: function ($$v) {
            _vm.$set(_vm.application, "applicantRemarks", $$v)
          },
          expression: "application.applicantRemarks",
        },
      }),
      _c("span", { staticClass: "text-label-gray text-xs float-right mr-4" }, [
        _vm._v("500 characters"),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
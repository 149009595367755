var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    { attrs: { id: _vm.SectionLabels.APPLICANT_DATA.key } },
    [
      _c("h3", { staticClass: "section-header" }, [_vm._v("Applicant")]),
      _c(
        "div",
        { staticClass: "items-start mb-5 form-input-container" },
        [
          _c("read-only-component", {
            attrs: {
              label: "First Name",
              value: _vm.applicant.identificationInformationFirstname,
              placeholder: "First Name",
            },
          }),
          _c("read-only-component", {
            attrs: {
              label: "Middle Name",
              value: _vm.applicant.identificationInformationMiddlename,
              placeholder: "Middle Name",
            },
          }),
          _c("read-only-component", {
            attrs: {
              label: "Last Name",
              value: _vm.applicant.identificationInformationLastname,
              placeholder: "Last Name",
            },
          }),
          _c(
            "div",
            { staticClass: "md:col-span-2 mt-1" },
            [
              _c("dropdown-nested-component", {
                attrs: {
                  id: _vm.FieldItemLabels.APPLICANT_TYPE.key,
                  label: _vm.FieldItemLabels.APPLICANT_TYPE.label,
                  helpText: _vm.helpText.applicationData.applicationType,
                  "data-cy": "applicantTypeSelector",
                  errorText: "Please select applicant type",
                  dropDownOptions: _vm.applicantTypeNestedDropDownOptions,
                  prefill:
                    !_vm.application.applicationType &&
                    _vm.applicant.applicantType,
                  placeholder: "Select Applicant Type",
                },
                on: {
                  validationState: function ($event) {
                    return _vm.$emit("validationState", $event)
                  },
                },
                model: {
                  value: _vm.application.applicationType,
                  callback: function ($$v) {
                    _vm.$set(_vm.application, "applicationType", $$v)
                  },
                  expression: "application.applicationType",
                },
              }),
              _vm._m(0),
            ],
            1
          ),
          _c("radio-component", {
            staticClass: "mt-1",
            attrs: {
              id: _vm.FieldItemLabels.DEMOGRAPHICS_DUAL_CITIZEN.key,
              label: _vm.FieldItemLabels.DEMOGRAPHICS_DUAL_CITIZEN.label,
              "data-cy": "isDualCitizenSelector",
              errorText: "Please indicate if applicant is a dual citizen.",
              radioItems: _vm.yesNoRadioOptions,
            },
            on: {
              validationState: function ($event) {
                return _vm.$emit("validationState", $event)
              },
            },
            model: {
              value: _vm.application.demographicsIsdualcitizen,
              callback: function ($$v) {
                _vm.$set(_vm.application, "demographicsIsdualcitizen", $$v)
              },
              expression: "application.demographicsIsdualcitizen",
            },
          }),
          _c("radio-component", {
            staticClass: "col-start-1 mt-3",
            attrs: {
              id: _vm.FieldItemLabels.SPONSORSHIP_IS_SPONSORED.key,
              label: _vm.FieldItemLabels.SPONSORSHIP_IS_SPONSORED.label,
              "data-cy": "isSponsoredSelector",
              errorText: "Please indicate if applicant is sponsored.",
              radioItems: _vm.yesNoRadioOptions,
            },
            on: {
              validationState: function ($event) {
                return _vm.$emit("validationState", $event)
              },
            },
            model: {
              value: _vm.application.sponsorshipIssponsored,
              callback: function ($$v) {
                _vm.$set(_vm.application, "sponsorshipIssponsored", $$v)
              },
              expression: "application.sponsorshipIssponsored",
            },
          }),
          _c("dropdown-component", {
            staticClass: "mt-3",
            attrs: {
              id: _vm.FieldItemLabels.SPONSORSHIP_UNIT.key,
              label: _vm.FieldItemLabels.SPONSORSHIP_UNIT.label,
              "data-cy": "SponsorSelector",
              errorText: "Please select a sponsor unit",
              values: _vm.sponsorUnitDropDownItems,
              placeholder: "Select one",
            },
            on: {
              validationState: function ($event) {
                return _vm.$emit("validationState", $event)
              },
            },
            model: {
              value: _vm.application.sponsorshipSponsorunit,
              callback: function ($$v) {
                _vm.$set(_vm.application, "sponsorshipSponsorunit", $$v)
              },
              expression: "application.sponsorshipSponsorunit",
            },
          }),
          _c("file-upload-component", {
            staticClass:
              "w-full pt-5 overflow-hidden place-self-end whitespace-nowrap",
            attrs: {
              id: _vm.FieldItemLabels.SPONSORSHIP_LETTER.key,
              label: _vm.FieldItemLabels.SPONSORSHIP_LETTER.label,
              bg: "val-button-blue",
              color: "white",
              hideLabel: true,
              border: false,
              errorText: "Please upload a Sponsorship Letter",
              uploadButtonText: "Upload Sponsorship Letter",
              uploadFieldName: "SponsorshipLetterUpload",
              downloadButtonText: "Download Sponsorship Letter",
            },
            model: {
              value: _vm.application.sponsorshipSponsorshipletterlocation,
              callback: function ($$v) {
                _vm.$set(
                  _vm.application,
                  "sponsorshipSponsorshipletterlocation",
                  $$v
                )
              },
              expression: "application.sponsorshipSponsorshipletterlocation",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.application.isRotc,
              expression: "application.isRotc",
            },
          ],
          staticClass: "mb-5 mt-14",
        },
        [
          _c("h4", [_vm._v("ROTC Cadet")]),
          _c(
            "div",
            { staticClass: "form-input-container" },
            [
              _c("date-selector-component", {
                attrs: {
                  id: _vm.FieldItemLabels.EXPECTED_ROTC_DATE.key,
                  label: _vm.FieldItemLabels.EXPECTED_ROTC_DATE.label,
                  "data-cy": "rotcGraduationDateSelector",
                  errorText: "Please select ROTC graduation date",
                },
                model: {
                  value:
                    _vm.application.academicInformationExpectedrotcgraduation,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.application,
                      "academicInformationExpectedrotcgraduation",
                      $$v
                    )
                  },
                  expression:
                    "application.academicInformationExpectedrotcgraduation",
                },
              }),
              _c("file-upload-component", {
                attrs: {
                  id: _vm.FieldItemLabels.ACADEMIC_ROTC_TRAINING_REPORT.key,
                  label:
                    _vm.FieldItemLabels.ACADEMIC_ROTC_TRAINING_REPORT.label,
                  "data-cy": "rotcFieldTrainingReportLocationFileUpload",
                  errorText: "Please upload an ROTC Field Training Report",
                  bg: "val-button-blue",
                  color: "white",
                  border: false,
                  uploadFieldName: "ROTCFieldTrainingReportUpload",
                },
                model: {
                  value:
                    _vm.application
                      .academicInformationRotcfieldtrainingreportlocation,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.application,
                      "academicInformationRotcfieldtrainingreportlocation",
                      $$v
                    )
                  },
                  expression:
                    "\n          application.academicInformationRotcfieldtrainingreportlocation\n        ",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "text-xxs text-label-gray font-lato" }, [
      _vm._v(
        " An applicant who is currently serving in the military is considered a 'Prior-Service' applicant."
      ),
      _c("br"),
      _vm._v("ROTC cadets should select 'ROTC'. "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
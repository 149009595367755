
import ButtonComponent from '@/components/ButtonComponent.vue';
import IconComponent from '@/components/IconComponent.vue';
import { Application } from '@/models/Entities/Application/Application';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Board } from '@/models/Entities/Board';
import { scrollToElement } from '@/util';
import {
  RequiredApplicationFieldItem,
  RequiredSection
} from '@/models/Entities/Application/RequiredFields/RequiredApplicationFields';
import { ApplicationCompletionStatus } from '@/models/Entities/Constants';

@Component<ApplicationFormStateComponent>({
  components: {
    ButtonComponent,
    IconComponent
  }
})
export default class ApplicationFormStateComponent extends Vue {
  @Prop({
    required: true
  })
  application!: Application;

  @Prop({
    required: true
  })
  boards!: Board[];

  @Prop({
    default: false
  })
  isUpdatingApplication!: boolean;

  showRemainingFields = false;
  scrollToElement = scrollToElement;

  update(): void {
    this.$emit('update');
  }

  toggleRequiredFields(): void {
    this.showRemainingFields = !this.showRemainingFields;
  }

  get updateButtonDisabled(): boolean {
    return this.isUpdatingApplication;
  }

  get showSections(): boolean {
    return this.showRemainingFields && !this.isComplete;
  }

  get isComplete(): boolean {
    return (
      this.application.progress.applicationStatus ===
      ApplicationCompletionStatus.COMPLETED
    );
  }

  get incompleteFields(): RequiredApplicationFieldItem[] {
    return this.application.incompleteFields;
  }

  get incompleteSections(): RequiredSection[] {
    return this.application.incompleteSections;
  }

  get selectedBoard(): Board | undefined {
    return this.boards.find(
      (board: Board) => board.id === this.application.board.id
    );
  }

  get boardTitle(): string {
    return this.selectedBoard?.title || 'Select a Board';
  }
}


import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { FormInputState } from '@/models/Forms/FormState';
import IconComponent from '@/components/IconComponent.vue';

@Component<FileUploadComponent>({
  components: { IconComponent }
})
export default class FileUploadComponent extends Vue {
  @Prop()
  readonly id?: string;

  @Prop()
  readonly value?: string;

  @Prop({ default: false })
  readonly required?: boolean;

  @Prop({ default: 'Please upload a File.' })
  readonly errorText?: string;

  @Prop({ default: false })
  readonly isSaving?: boolean;

  @Prop({ default: 'Upload File' })
  readonly label?: string;

  @Prop({ default: 'Upload File' })
  readonly uploadButtonText?: string;

  @Prop({ default: 'Download' })
  readonly downloadButtonText?: string;

  @Prop({ default: false })
  readonly multiple?: boolean;

  @Prop({
    default: ''
  })
  readonly uploadFieldName!: string;

  @Prop({ default: false })
  readonly readonly!: boolean;

  @Prop({ default: false })
  readonly hideLabel!: boolean;

  @Prop({ default: true })
  border!: boolean;

  @Prop({ default: 'blue' })
  color!: string;

  @Prop({ default: 'white' })
  bg!: string;

  // eslint-disable-next-line
  private numOfFiles = -1;
  private isPristine = true;
  private fileLocation: string | null = null;
  private isActive = false;

  $refs!: {
    fileInput: HTMLInputElement;
  };

  get classes() {
    return [
      `text-${this.color}-500`,
      `text-${this.color}`,
      `border-${this.color}-500`,
      `bg-${this.bg}-500`,
      `bg-${this.bg}`,
      this.border ? 'border' : 'border-0'
    ];
  }

  get showError() {
    return !this.isPristine && !this.isValid;
  }

  get isValid() {
    return !this.required || this.numOfFiles > 0;
  }

  get isLoading() {
    if (!this.isActive) return false;
    const progress = this.$store.getters['storageModule/progress'];

    return progress < 100 && progress > 0;
  }

  private async updateValue(files: FileList) {
    this.numOfFiles = files.length;
    const file = files[0];
    if (files.length > 0) {
      this.isActive = true;
      const uploadedLocation = await this.$store.dispatch(
        'storageModule/uploadObject',
        file
      );
      this.fileLocation = uploadedLocation;
      this.isActive = false;
      if (uploadedLocation) {
        this.$store.dispatch('snackBarModule/enqueue', {
          message: `"${file.name}" Uploaded`,
          icon: 'checkGreen'
        });
      }
      this.$emit('input', this.fileLocation);
      this.$emit('blur');
    }
  }

  private async downloadObject() {
    await this.$store.dispatch('storageModule/downloadObject', this.value);
  }

  private clearFile() {
    const htmlEl = this.$refs.fileInput;
    this.numOfFiles = -1;
    this.fileLocation = '';
    this.$emit('input', '');
    htmlEl.value = '';
  }

  @Watch('isValid')
  private emitValidationState() {
    this.$emit(
      'validationState',
      new FormInputState({
        isValid: this.isValid,
        errorMessage: this.errorText
      })
    );
  }

  created(): void {
    this.emitValidationState();
  }

  onBlur(): void {
    this.isPristine = false;
    this.$emit('blur');
  }
}

import { LabelItems } from '@/models/Entities/types';
import { Vue, Component, VModel, Prop } from 'vue-property-decorator';
import { Application } from '../models/Entities/Application/Application';
import {
  FieldItemLabels,
  SectionLabels
} from '../models/Entities/Application/RequiredFields/RequiredApplicationFields';
import { Applicant } from '../models/Entities/Personas/Applicant';
import { RadioOptions, yesNoOptions } from '../models/Forms/FormOption';
import Regex from '../models/Forms/Regex';

@Component
export default class ApplicationSectionMixin extends Vue {
  @VModel({
    required: true
  })
  application!: Application;

  @Prop({
    required: true
  })
  applicant!: Applicant;

  FieldItemLabels: LabelItems = FieldItemLabels;
  Regex = Regex;
  SectionLabels: LabelItems = SectionLabels;
  yesNoRadioOptions: RadioOptions[] = yesNoOptions;
}


import { Component, Mixins } from 'vue-property-decorator';
import TextAreaComponent from '@/components/Forms/Elements/TextAreaComponent.vue';
import RadioComponent from '@/components/Forms/Elements/RadioComponent.vue';
import ApplicationSectionMixin from '@/mixins/ApplicationSectionMixin';

@Component<DroppedOnRequest>({
  components: {
    RadioComponent,
    TextAreaComponent
  }
})
export default class DroppedOnRequest extends Mixins(ApplicationSectionMixin) {}

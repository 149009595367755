
import { Component, Prop, Mixins } from 'vue-property-decorator';
import BoardSelectorComponent from '@/components/Board/BoardSelectorComponent.vue';
import { PropType } from 'vue';
import { Board } from '@/models/Entities/Board';
import { SectionLabels } from '@/models/Entities/Application/RequiredFields/RequiredApplicationFields';
import ApplicationSectionMixin from '@/mixins/ApplicationSectionMixin';

@Component<ApplicationFormBoardSelectionSection>({
  components: {
    BoardSelectorComponent
  }
})
export default class ApplicationFormBoardSelectionSection extends Mixins(
  ApplicationSectionMixin
) {
  @Prop({
    required: true,
    type: Array as PropType<Array<Board>>
  })
  boards!: Board[];

  SectionLabels = SectionLabels;
}

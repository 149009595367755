var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { attrs: { id: _vm.id } }, [
    _vm.label
      ? _c("label", { staticClass: "block mb-0.5 text-label-gray" }, [
          _vm._v(" " + _vm._s(_vm.label) + " "),
        ])
      : _vm._e(),
    _c("textarea", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.textValue,
          expression: "textValue",
        },
      ],
      staticClass:
        "w-full px-3 py-2 leading-tight text-gray-700 border rounded appearance-none focus:outline-none focus:shadow-outline",
      class: {
        "border-red-500": _vm.showError,
        "h-16": _vm.small,
        "h-48": !_vm.small,
        "bg-gray-200": _vm.readonly,
      },
      attrs: {
        disabled: _vm.readonly,
        "data-cy": "textAreaComponentInput",
        type: "text",
        placeholder: _vm.placeholder,
        tabindex: _vm.tabIndex,
        maxlength: _vm.maxLength,
      },
      domProps: { value: _vm.textValue },
      on: {
        blur: _vm.onBlur,
        input: [
          function ($event) {
            if ($event.target.composing) return
            _vm.textValue = $event.target.value
          },
          function ($event) {
            return _vm.updateValue($event.target.value)
          },
        ],
      },
    }),
    _vm._v(" "),
    _vm.showError
      ? _c("div", { staticClass: "mt-2 text-xs leading-tight text-red-500" }, [
          _vm._v(" " + _vm._s(_vm.errorText) + " "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }

import { Component, Mixins } from 'vue-property-decorator';
import DropdownComponent from '@/components/Forms/Elements/DropdownComponent.vue';
import { EFlightPhysicalTypes, EScrollStatusTypes } from '@/enums';
import { getInputOptions } from '@/util/getInputOptions';
import ApplicationSectionMixin from '@/mixins/ApplicationSectionMixin';

@Component<ApplicationFormEndorsementsSection>({
  components: {
    DropdownComponent
  }
})
export default class ApplicationFormEndorsementsSection extends Mixins(
  ApplicationSectionMixin
) {
  flightPhysicalTypes = getInputOptions(EFlightPhysicalTypes);
  scrollStatusTypes = getInputOptions(EScrollStatusTypes);
}


import { Component, Mixins } from 'vue-property-decorator';
import NumberInputComponent from '@/components/Forms/Elements/NumberInputComponent.vue';
import TextInputComponent from '@/components/Forms/Elements/TextInputComponent.vue';
import DropdownComponent from '@/components/Forms/Elements/DropdownComponent.vue';
import DateSelectorComponent from '@/components/Forms/Elements/DateSelectorComponent.vue';
import RadioComponent from '@/components/Forms/Elements/RadioComponent.vue';
import { getInputOptions } from '@/util/getInputOptions';
import { EDegreeTypes } from '@/enums/EDegreeTypes';
import ApplicationSectionMixin from '@/mixins/ApplicationSectionMixin';

@Component<ApplicationFormAcademicDataSection>({
  components: {
    DateSelectorComponent,
    DropdownComponent,
    NumberInputComponent,
    RadioComponent,
    TextInputComponent
  }
})
export default class ApplicationFormAcademicDataSection extends Mixins(
  ApplicationSectionMixin
) {
  degreeDropdownOptions = getInputOptions(EDegreeTypes);
}

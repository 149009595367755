
import { Component, Vue, Prop } from 'vue-property-decorator';
@Component<ReadOnlyComponent>({})
export default class ReadOnlyComponent extends Vue {
  @Prop({ default: '' })
  label?: string;

  @Prop({ default: 'None' })
  value!: string;

  @Prop({ default: 'None selected' })
  placeholder?: string;
}

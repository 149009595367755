import { ApplicationCompletionStatus } from '../Constants';

export type ApplicationProgress = {
  applicationStatus: ApplicationCompletionStatus;
  completed: number;
  total: number;
};

export const APPLICATION_HELP_TEXT = {
  applicationData: {
    applicationType: `An applicant who is currently serving in the military is considered a 'Prior-Service' applicant. ROTC cadets should select 'ROTC'.`
  },

  priorService: {
    arb: 'An ARB is required for all rated, prior-service (non-Air Force) officers and commissioned warrant officers. If the member was aircrew in another service and is joining the Air Force as a rated crew member, regardless of AFSC, the ARB package is required by AFMAN 11-402. This review ensures the member was never disqualified from aviation service, was never involved in any aircraft accident or mishap (or provides statements on the incident), reviews their qualification history, awards the appropriate rating and establishes their aviation service date.'
  }
};

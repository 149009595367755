var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    {
      staticClass: "flex flex-col",
      attrs: { id: _vm.SectionLabels.BOARD.key },
    },
    [
      _c("h2", { staticClass: "mb-4" }, [_vm._v("Board Application")]),
      _c("h3", { staticClass: "section-header" }, [_vm._v("Board")]),
      _c(
        "div",
        { staticClass: "form-input-container" },
        [
          _c("board-selector-component", {
            staticClass: "md:col-span-2",
            attrs: { boards: _vm.boards },
            model: {
              value: _vm.application.board.id,
              callback: function ($$v) {
                _vm.$set(_vm.application.board, "id", $$v)
              },
              expression: "application.board.id",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
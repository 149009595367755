
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { FormInputState } from '@/models/Forms/FormState';

@Component<TextAreaComponent>({})
export default class TextAreaComponent extends Vue {
  @Prop()
  readonly id?: string;

  @Prop()
  readonly value?: string | number;

  @Prop()
  readonly label?: string;

  @Prop()
  readonly maxLength?: number;

  @Prop()
  readonly placeholder?: string;

  @Prop()
  readonly tabIndex?: number;

  @Prop({ default: () => new RegExp(/^(?=.{1,250}$).*/) })
  readonly regex?: RegExp;

  @Prop({ default: false })
  readonly required?: boolean;

  @Prop({ default: 'Please enter a value' })
  readonly errorText?: string;

  @Prop({ default: false })
  readonly small?: boolean;

  @Prop({ default: false })
  readonly readonly!: boolean;

  private isPristine = true;
  private textValue = '';

  get showError() {
    return !this.isPristine && !this.isValid;
  }

  get isValid() {
    if ((!this.required && this.textValue === '') || !this.regex) return true;
    return this.regex.test(this.textValue);
  }

  @Watch('isValid')
  private emitValidationState() {
    this.$emit(
      'validationState',
      new FormInputState({
        isValid: this.isValid,
        errorMessage: this.errorText
      })
    );
  }

  private updateValue(value: string) {
    this.textValue = value;
    this.$emit('input', value);
  }

  mounted() {
    if (this.value) {
      this.textValue = this.value.toString();
    }
    this.emitValidationState();
  }

  onBlur() {
    this.isPristine = false;
    this.$emit('blur');
  }
}


import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator';
import ApplicationForm from '@/components/Forms/Application/ApplicationFormComponentV2.vue';
import { Application } from '@/models/Entities/Application/Application';
import { Board } from '@/models/Entities/Board';
import { Applicant } from '@/models/Entities/Personas/Applicant';
import SnackbarMixin from '@/mixins/snackbarMixin';
import { formatDateTime } from '@/services/formatService';
import { ESectionStatus } from '@/enums/ESectionStatus';
import Routes from '@/router/Routes';
import { sortDatesDescending } from '@/util/date';
import { SectionStatusType } from '@/components/types';
import generateScrollToCallback from '@/util/generateScrollToCallback';
import CautionModalComponent from '@/components/Modals/CautionModalComponent.vue';
import { EIconColors } from '@/enums';
import { archiveApplicationCallback } from '@/util';

@Component<ApplicantApplicationDetailsPage>({
  components: {
    ApplicationForm,
    CautionModalComponent
  }
})
export default class ApplicantApplicationDetailsPage extends Mixins(
  SnackbarMixin,
  Vue
) {
  @Prop({
    required: true
  })
  applicant!: Applicant;

  @Prop({
    required: true
  })
  application!: Application;

  @Prop({
    default: () => []
  })
  boards!: Board[];

  @Prop()
  currentBoard!: Board;

  @Prop()
  isUpdatingApplication!: boolean;

  pastApplicationHeader = 'Application History';

  applications = [];

  model: Application = new Application();

  created(): void {
    this.model = new Application(this.application);
  }

  @Watch('$route.params', { immediate: true })
  async fetchData(): Promise<void> {
    this.applications = await this.$store.dispatch(
      'applicantModule/findApplications',
      {
        candidateIds: [this.applicant.id],
        archived: false
      }
    );
    this.setSectionStatus();
  }

  $refs!: {
    cautionModalComponent: CautionModalComponent;
  };

  get pastApplicationEntries(): SectionStatusType[] {
    const pastApplications = this.applications
      .filter(
        (application: Application) => application.id !== this.application.id
      )
      .sort((applicationOne: Application, applicationTwo: Application) =>
        sortDatesDescending(
          applicationOne.createDateTime,
          applicationTwo.createDateTime
        )
      );
    return pastApplications.map((data: Application, index) => {
      return {
        text: formatDateTime(data.createDateTime),
        status: data.isComplete
          ? ESectionStatus.COMPLETE
          : ESectionStatus.PARTIAL,
        onClick: () => {
          this.$router.push({
            name: Routes.V2_APPLICANT_APPLICATION_DETAILS,
            params: {
              id: String(this.applicant.id),
              applicationId: String(data.id)
            }
          });
        },
        extraAction: {
          icon: ['far', 'trash-can'],
          iconColor: EIconColors.RED,
          onClick: async () => {
            await archiveApplicationCallback(
              this.applicant,
              data,
              index,
              this.pastApplicationHeader,
              this.$refs.cautionModalComponent
            );
          }
        }
      };
    });
  }

  setSectionStatus(): void {
    this.$store.dispatch('profileSideNavigationModule/setSections', [
      {
        header: 'Application',
        entries: this.currentApplicationSection
      },
      {
        header: this.pastApplicationHeader,
        entries: this.pastApplicationEntries
      }
    ]);
  }

  get currentApplicationSection(): SectionStatusType[] {
    return this.model.requiredSections.map((field) => {
      return {
        text: field.section.label,
        status: field.status,
        onClick: generateScrollToCallback(field.section.key)
      };
    });
  }

  async updateApplication(payload: Partial<Application>): Promise<void> {
    try {
      const applicationId = String(this.application.id);
      const result = await this.$store.dispatch(
        'applicantModule/patchApplication',
        {
          applicationId,
          payload
        }
      );
      // catch errors from the server
      if (result instanceof Error) {
        throw result;
      } else {
        this.setSectionStatus();
        await this.showSnackbar({
          message: 'Application successfully updated',
          icon: 'checkGreen'
        });
      }
    } catch (err) {
      this.showSnackbar({
        message: 'There was an error updating the application',
        icon: 'x'
      });
    }
  }
}

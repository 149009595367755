var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    { attrs: { id: _vm.SectionLabels.FLYING_EXPERIENCE.key } },
    [
      _c("h3", { staticClass: "section-header" }, [
        _vm._v("Flying Experience"),
      ]),
      _c(
        "div",
        { staticClass: "mb-10 form-input-container gap-y-6" },
        [
          _c("radio-component", {
            attrs: {
              id: _vm.FieldItemLabels.FLYING_EXPERIENCE_PRIVATE_LICENSE.key,
              label:
                _vm.FieldItemLabels.FLYING_EXPERIENCE_PRIVATE_LICENSE.label,
              "data-cy": "flyExperienceHasPrivateLicenseSelector",
              errorText:
                "Please indicate if applicant has a FAA pilot license (Student or Higher)",
              radioItems: _vm.yesNoRadioOptions,
            },
            on: {
              validationState: function ($event) {
                return _vm.$emit("validationState", $event)
              },
            },
            model: {
              value: _vm.application.flyExperienceHasprivatelicense,
              callback: function ($$v) {
                _vm.$set(_vm.application, "flyExperienceHasprivatelicense", $$v)
              },
              expression: "application.flyExperienceHasprivatelicense",
            },
          }),
          _c("dropdown-component", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.application.flyExperienceHasprivatelicense,
                expression: "application.flyExperienceHasprivatelicense",
              },
            ],
            staticClass: "col-start-1",
            attrs: {
              id: _vm.FieldItemLabels
                .FLYING_EXPERIENCE_CIVILIAN_PILOT_CERTIFICATE.key,
              label:
                _vm.FieldItemLabels.FLYING_EXPERIENCE_CIVILIAN_PILOT_CERTIFICATE
                  .label,
              "data-cy": "civilianPilotCertificateSelector",
              errorText: "Please select type of certificate",
              values: _vm.civilianPilotCertificateDropdownOptions,
              placeholder: "Select one",
            },
            on: {
              validationState: function ($event) {
                return _vm.$emit("validationState", $event)
              },
            },
            model: {
              value: _vm.application.flyExperienceCivilianpilotcertificate,
              callback: function ($$v) {
                _vm.$set(
                  _vm.application,
                  "flyExperienceCivilianpilotcertificate",
                  $$v
                )
              },
              expression: "application.flyExperienceCivilianpilotcertificate",
            },
          }),
          _c("dropdown-component", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.application.flyExperienceHasprivatelicense,
                expression: "application.flyExperienceHasprivatelicense",
              },
            ],
            attrs: {
              id: _vm.FieldItemLabels.FLYING_EXPERIENCE_FLIGHT_RATING_CATEGORY
                .key,
              label:
                _vm.FieldItemLabels.FLYING_EXPERIENCE_FLIGHT_RATING_CATEGORY
                  .label,
              "data-cy": "civilianFlightRatingCategorySelector",
              errorText: "Please select type of category",
              values: _vm.civilianFlightRatingCategoryDropdownOptions,
              placeholder: "Select one",
            },
            on: {
              validationState: function ($event) {
                return _vm.$emit("validationState", $event)
              },
            },
            model: {
              value: _vm.application.flyExperienceCivilianflightratingcategory,
              callback: function ($$v) {
                _vm.$set(
                  _vm.application,
                  "flyExperienceCivilianflightratingcategory",
                  $$v
                )
              },
              expression:
                "application.flyExperienceCivilianflightratingcategory",
            },
          }),
          _c("dropdown-component", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.application.flyExperienceHasprivatelicense,
                expression: "application.flyExperienceHasprivatelicense",
              },
            ],
            attrs: {
              id: _vm.FieldItemLabels.FLYING_EXPERIENCE_FLIGHT_RATING_CLASS.key,
              label:
                _vm.FieldItemLabels.FLYING_EXPERIENCE_FLIGHT_RATING_CLASS.label,
              "data-cy": "civilianFlightRatingClassSelector",
              errorText: "Please select type of class",
              values: _vm.civilianFlightRatingClassDropdownOptions,
              placeholder: "Select one",
            },
            on: {
              validationState: function ($event) {
                return _vm.$emit("validationState", $event)
              },
            },
            model: {
              value: _vm.application.flyExperienceCivilianflightratingclass,
              callback: function ($$v) {
                _vm.$set(
                  _vm.application,
                  "flyExperienceCivilianflightratingclass",
                  $$v
                )
              },
              expression: "application.flyExperienceCivilianflightratingclass",
            },
          }),
          _c("dropdown-component", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.application.flyExperienceHasprivatelicense,
                expression: "application.flyExperienceHasprivatelicense",
              },
            ],
            attrs: {
              id: _vm.FieldItemLabels.FLYING_EXPERIENCE_INSTRUCTOR_CERTIFICATION
                .key,
              label:
                _vm.FieldItemLabels.FLYING_EXPERIENCE_INSTRUCTOR_CERTIFICATION
                  .label,
              "data-cy": "hasLicenseSelector",
              errorText: "Please enter aeronautical rating",
              values: _vm.civilianFlightCFIDropdownOptions,
              placeholder: "Select one",
            },
            on: {
              validationState: function ($event) {
                return _vm.$emit("validationState", $event)
              },
            },
            model: {
              value: _vm.application.flyExperienceCivilianflightcfi,
              callback: function ($$v) {
                _vm.$set(_vm.application, "flyExperienceCivilianflightcfi", $$v)
              },
              expression: "application.flyExperienceCivilianflightcfi",
            },
          }),
          _c("text-input-component", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.application.flyExperienceHasprivatelicense,
                expression: "application.flyExperienceHasprivatelicense",
              },
            ],
            attrs: {
              id: _vm.FieldItemLabels.FLYING_EXPERIENCE_TOTAL_HOURS.key,
              label: _vm.FieldItemLabels.FLYING_EXPERIENCE_TOTAL_HOURS.label,
              "data-cy": "totalCivilianFlightHoursInput",
              type: "number",
              maxlength: "8",
              regex: _vm.Regex.number,
              errorText: "Please enter civilian flight hours",
            },
            on: {
              validationState: function ($event) {
                return _vm.$emit("validationState", $event)
              },
            },
            model: {
              value: _vm.application.flyExperienceCiviliantotalflighthours,
              callback: function ($$v) {
                _vm.$set(
                  _vm.application,
                  "flyExperienceCiviliantotalflighthours",
                  $$v
                )
              },
              expression: "application.flyExperienceCiviliantotalflighthours",
            },
          }),
          _c("date-selector-component", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.application.flyExperienceHasprivatelicense,
                expression: "application.flyExperienceHasprivatelicense",
              },
            ],
            attrs: {
              id: _vm.FieldItemLabels.FLYING_EXPERIENCE_LAST_DATE_FLOWN.key,
              label:
                _vm.FieldItemLabels.FLYING_EXPERIENCE_LAST_DATE_FLOWN.label,
              "data-cy": "flyExperienceLastDateFlownSelector",
              yearsForward: 0,
              errorText: "Please select last date flown",
            },
            on: {
              validationState: function ($event) {
                return _vm.$emit("validationState", $event)
              },
            },
            model: {
              value: _vm.application.flyExperienceLastdateflown,
              callback: function ($$v) {
                _vm.$set(_vm.application, "flyExperienceLastdateflown", $$v)
              },
              expression: "application.flyExperienceLastdateflown",
            },
          }),
          _c("file-upload-component", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.application.flyExperienceHasprivatelicense,
                expression: "application.flyExperienceHasprivatelicense",
              },
            ],
            attrs: {
              id: _vm.FieldItemLabels.FLYING_EXPERIENCE_UPLOAD_PILOTS_LICENSE
                .key,
              label:
                _vm.FieldItemLabels.FLYING_EXPERIENCE_UPLOAD_PILOTS_LICENSE
                  .label,
              "data-cy": "flyExperiencePilotsLicenseFileUpload",
              bg: "blue",
              color: "white",
              errorText: "Please upload a pilot license",
              uploadFieldName: "flightFileUpload",
            },
            model: {
              value: _vm.application.flyExperiencePilotslicenselocation,
              callback: function ($$v) {
                _vm.$set(
                  _vm.application,
                  "flyExperiencePilotslicenselocation",
                  $$v
                )
              },
              expression: "application.flyExperiencePilotslicenselocation",
            },
          }),
          _c("file-upload-component", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.application.flyExperienceHasprivatelicense,
                expression: "application.flyExperienceHasprivatelicense",
              },
            ],
            attrs: {
              id: _vm.FieldItemLabels.FLYING_EXPERIENCE_UPLOAD_LOG_BOOK.key,
              label:
                _vm.FieldItemLabels.FLYING_EXPERIENCE_UPLOAD_LOG_BOOK.label,
              "data-cy": "lastPageOfPilotLogBookLocationFileUpload",
              bg: "blue",
              color: "white",
              errorText: "Please upload the Last Page of Pilot Logbook",
              uploadFieldName: "LastPageOfPilotLogBookUpload",
            },
            model: {
              value:
                _vm.application.flyExperienceLastpageofpilotlogbooklocation,
              callback: function ($$v) {
                _vm.$set(
                  _vm.application,
                  "flyExperienceLastpageofpilotlogbooklocation",
                  $$v
                )
              },
              expression:
                "application.flyExperienceLastpageofpilotlogbooklocation",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
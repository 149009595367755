
import { Component, Mixins } from 'vue-property-decorator';
import DateSelectorComponent from '@/components/Forms/Elements/DateSelectorComponent.vue';
import TextInputComponent from '@/components/Forms/Elements/TextInputComponent.vue';
import ApplicationSectionMixin from '@/mixins/ApplicationSectionMixin';

@Component<ApplicationFormTestingDataSection>({
  components: {
    DateSelectorComponent,
    TextInputComponent
  }
})
export default class ApplicationFormTestingDataSection extends Mixins(
  ApplicationSectionMixin
) {}

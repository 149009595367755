
import IconComponent from '@/components/IconComponent.vue';
import { Application } from '@/models/Entities/Application/Application';
import { Applicant } from '@/models/Entities/Personas/Applicant';
import { Component, Prop, Vue } from 'vue-property-decorator';
import StickyContainerComponent from '@/components/StickyContainerComponent.vue';
import ApplicationFormStateComponent from '@/components/Forms/Application/ApplicationFormStateComponent.vue';
import { Board } from '@/models/Entities/Board';
// Form Partials
import AcademicData from '@/components/Forms/Application/Sections/AcademicData.vue';
import ApplicantData from '@/components/Forms/Application/Sections/ApplicantData.vue';
import BoardSelection from '@/components/Forms/Application/Sections/BoardSelection.vue';
import PriorService from '@/components/Forms/Application/Sections/PriorService.vue';
import DroppedOnRequest from '@/components/Forms/Application/Sections/DroppedOnRequest.vue';
import TrainingPreferences from '@/components/Forms/Application/Sections/TrainingPreferences.vue';
import FlyingExperience from '@/components/Forms/Application/Sections/FlyingExperience.vue';
import ExceptionToPolicy from '@/components/Forms/Application/Sections/ExceptionToPolicy.vue';
import BasePreferences from '@/components/Forms/Application/Sections/BasePreferences.vue';
import ApplicantRemarks from '@/components/Forms/Application/Sections/ApplicantRemarks.vue';
import Endorsements from '@/components/Forms/Application/Sections/Endorsements.vue';
import TestingData from '@/components/Forms/Application/Sections/TestingData.vue';
import PhysicalAssessment from '@/components/Forms/Application/Sections/PhysicalAssessment.vue';

@Component<ApplicationFormComponentV2>({
  components: {
    AcademicData,
    ApplicantData,
    ApplicantRemarks,
    ApplicationFormStateComponent,
    BasePreferences,
    BoardSelection,
    DroppedOnRequest,
    Endorsements,
    ExceptionToPolicy,
    FlyingExperience,
    IconComponent,
    PriorService,
    PhysicalAssessment,
    StickyContainerComponent,
    TestingData,
    TrainingPreferences
  }
})
export default class ApplicationFormComponentV2 extends Vue {
  @Prop({
    required: true
  })
  applicant!: Applicant;

  @Prop({
    required: true
  })
  application!: Application;

  @Prop({
    required: true
  })
  boards!: Board[];

  @Prop({
    default: false
  })
  isUpdatingApplication!: boolean;

  update(): void {
    this.$emit('update', this.application);
  }
}

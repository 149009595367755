
import { Component, Mixins } from 'vue-property-decorator';
import TextAreaComponent from '@/components/Forms/Elements/TextAreaComponent.vue';
import CheckboxComponent from '@/components/Forms/Elements/CheckboxComponent.vue';
import FileUploadComponent from '@/components/Forms/Elements/FileUploadComponent.vue';
import RadioComponent from '@/components/Forms/Elements/RadioComponent.vue';
import { CheckBoxOptions } from '@/models/Forms/FormOption';
import { EExceptionToPolicyTypes } from '@/enums';
import { getInputOptions } from '@/util/getInputOptions';
import ApplicationSectionMixin from '@/mixins/ApplicationSectionMixin';

@Component<ApplicationFormExceptionToPolicySection>({
  components: {
    CheckboxComponent,
    FileUploadComponent,
    RadioComponent,
    TextAreaComponent
  }
})
export default class ApplicationFormExceptionToPolicySection extends Mixins(
  ApplicationSectionMixin
) {
  exceptionToPolicyCheckboxOptions: CheckBoxOptions[] = getInputOptions(
    EExceptionToPolicyTypes
  );

  get showTextArea(): boolean {
    return (
      this.application.exceptionToPolicyReasons?.includes(
        EExceptionToPolicyTypes.OTHER
      ) === true
    );
  }

  get etpMemoRequired(): boolean {
    return (
      (this.application.exceptionToPolicyReasons &&
        this.application.exceptionToPolicyReasons.every(
          (i) => i != EExceptionToPolicyTypes.NONE
        ) &&
        this.application.exceptionToPolicyReasons.length > 0) ||
      false
    );
  }
}

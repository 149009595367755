
import { Component, Mixins } from 'vue-property-decorator';
import TextInputComponent from '@/components/Forms/Elements/TextInputComponent.vue';
import DateSelectorComponent from '@/components/Forms/Elements/DateSelectorComponent.vue';
import RadioComponent from '@/components/Forms/Elements/RadioComponent.vue';
import { ESponsorUnits } from '@/enums';
import { getInputOptions } from '@/util/getInputOptions';
import FileUploadComponent from '@/components/Forms/Elements/FileUploadComponent.vue';
import ApplicationSectionMixin from '@/mixins/ApplicationSectionMixin';
import DropdownNestedComponent from '@/components/Forms/Elements/DropdownNestedComponent.vue';
import DropdownComponent from '@/components/Forms/Elements/DropdownComponent.vue';
import { ApplicantTypeNestedDropDownOption } from '@/models/Entities/Personas/Constants';
import { APPLICATION_HELP_TEXT } from '@/models/Entities/Application/Constants';
import ReadOnlyComponent from '../../Elements/ReadOnlyComponent.vue';

@Component<ApplicantData>({
  components: {
    DateSelectorComponent,
    DropdownNestedComponent,
    FileUploadComponent,
    RadioComponent,
    TextInputComponent,
    DropdownComponent,
    ReadOnlyComponent
  }
})
export default class ApplicantData extends Mixins(ApplicationSectionMixin) {
  applicantTypeNestedDropDownOptions = ApplicantTypeNestedDropDownOption;
  sponsorUnitDropDownItems = getInputOptions(ESponsorUnits);
  helpText = APPLICATION_HELP_TEXT;
}

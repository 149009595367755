var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm.label
      ? _c("label", { staticClass: "field-labels" }, [
          _vm._v(" " + _vm._s(_vm.label) + " "),
        ])
      : _vm._e(),
    _c("div", { staticClass: "h-9 flex items-center" }, [
      _c("div", { staticClass: "text-sm text-dark pl-3" }, [
        _vm._v(_vm._s(_vm.value || _vm.placeholder)),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
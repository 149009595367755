var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", { attrs: { id: _vm.SectionLabels.ACADEMIC_DATA.key } }, [
    _c("h3", { staticClass: "section-header" }, [
      _vm._v("Academic Information"),
    ]),
    _c(
      "div",
      { staticClass: "mb-10 form-input-container gap-y-5" },
      [
        _c("text-input-component", {
          attrs: {
            id: _vm.FieldItemLabels.ACADEMIC_INSTITUTION.key,
            label: _vm.FieldItemLabels.ACADEMIC_INSTITUTION.label,
            "data-cy": "academicInstitutionInput",
            prefill:
              !_vm.application.academicInformationInstitution &&
              _vm.applicant.academicInformationInstitution,
            placeholder: "School",
          },
          on: {
            validationState: function ($event) {
              return _vm.$emit("validationState", $event)
            },
          },
          model: {
            value: _vm.application.academicInformationInstitution,
            callback: function ($$v) {
              _vm.$set(_vm.application, "academicInformationInstitution", $$v)
            },
            expression: "application.academicInformationInstitution",
          },
        }),
        _c("text-input-component", {
          attrs: {
            id: _vm.FieldItemLabels.ACADEMIC_MAJOR.key,
            label: _vm.FieldItemLabels.ACADEMIC_MAJOR.label,
            "data-cy": "academicMajorInput",
            prefill:
              !_vm.application.academicInformationMajor &&
              _vm.applicant.academicInformationMajor,
            placeholder: "Major",
          },
          on: {
            validationState: function ($event) {
              return _vm.$emit("validationState", $event)
            },
          },
          model: {
            value: _vm.application.academicInformationMajor,
            callback: function ($$v) {
              _vm.$set(_vm.application, "academicInformationMajor", $$v)
            },
            expression: "application.academicInformationMajor",
          },
        }),
        _c("dropdown-component", {
          attrs: {
            id: _vm.FieldItemLabels.ACADEMIC_DEGREE.key,
            label: _vm.FieldItemLabels.ACADEMIC_DEGREE.label,
            "data-cy": "academicDegreeSelector",
            placeholder: "Select one",
            values: _vm.degreeDropdownOptions,
            prefill:
              !_vm.application.academicInformationDegree &&
              _vm.applicant.academicInformationDegree,
          },
          on: {
            validationState: function ($event) {
              return _vm.$emit("validationState", $event)
            },
          },
          model: {
            value: _vm.application.academicInformationDegree,
            callback: function ($$v) {
              _vm.$set(_vm.application, "academicInformationDegree", $$v)
            },
            expression: "application.academicInformationDegree",
          },
        }),
        _c("radio-component", {
          attrs: {
            id: _vm.FieldItemLabels.ACADEMIC_IS_AVIATION_GRADUATE.key,
            label: _vm.FieldItemLabels.ACADEMIC_IS_AVIATION_GRADUATE.label,
            "data-cy": "aviationProgramGraduateSelector",
            radioItems: _vm.yesNoRadioOptions,
          },
          on: {
            validationState: function ($event) {
              return _vm.$emit("validationState", $event)
            },
          },
          model: {
            value: _vm.application.academicInformationAviationprogramgraduate,
            callback: function ($$v) {
              _vm.$set(
                _vm.application,
                "academicInformationAviationprogramgraduate",
                $$v
              )
            },
            expression:
              "application.academicInformationAviationprogramgraduate",
          },
        }),
        _c("date-selector-component", {
          attrs: {
            id: _vm.FieldItemLabels.ACADEMIC_GRADUATION_DATE.key,
            label: _vm.FieldItemLabels.ACADEMIC_GRADUATION_DATE.label,
            "data-cy": "academicGraduationDateSelector",
            prefill:
              !_vm.application.academicInformationGraduationdate &&
              _vm.applicant.academicInformationGraduationdate,
          },
          on: {
            validationState: function ($event) {
              return _vm.$emit("validationState", $event)
            },
          },
          model: {
            value: _vm.application.academicInformationGraduationdate,
            callback: function ($$v) {
              _vm.$set(
                _vm.application,
                "academicInformationGraduationdate",
                $$v
              )
            },
            expression: "application.academicInformationGraduationdate",
          },
        }),
        _c("number-input-component", {
          attrs: {
            id: _vm.FieldItemLabels.ACADEMIC_GPA.key,
            label: _vm.FieldItemLabels.ACADEMIC_GPA.label,
            "data-cy": "gpaInput",
            step: 0.01,
            max: 4.9,
            maxlength: 4,
            placeholder: "3.8",
            prefill:
              !_vm.application.academicInformationGpa &&
              _vm.applicant.academicInformationGpa,
          },
          on: {
            validationState: function ($event) {
              return _vm.$emit("validationState", $event)
            },
          },
          model: {
            value: _vm.application.academicInformationGpa,
            callback: function ($$v) {
              _vm.$set(_vm.application, "academicInformationGpa", $$v)
            },
            expression: "application.academicInformationGpa",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }

import { Component, Mixins } from 'vue-property-decorator';
import TextAreaComponent from '@/components/Forms/Elements/TextAreaComponent.vue';
import RadioComponent from '@/components/Forms/Elements/RadioComponent.vue';
import DateSelector from '@/components/Forms/Elements/DateSelectorComponent.vue';
import DropdownComponent from '@/components/Forms/Elements/DropdownComponent.vue';
import { DropdownOptions } from '@/models/Forms/FormOption';
import { EPreferredUptBaseTypes } from '@/enums';
import ApplicationSectionMixin from '@/mixins/ApplicationSectionMixin';

@Component<ApplicationFormBasePreferencesSection>({
  components: {
    DateSelector,
    DropdownComponent,
    RadioComponent,
    TextAreaComponent
  }
})
export default class ApplicationFormBasePreferencesSection extends Mixins(
  ApplicationSectionMixin
) {
  get uptBaseDropdownOptions(): DropdownOptions[] {
    const enumValues = Object.values(EPreferredUptBaseTypes);
    return enumValues.map((item) => {
      const disabled = this.application.currentDesiredUptBases?.includes(item);
      return {
        label: item,
        value: item,
        disabled
      };
    });
  }
}

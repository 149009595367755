var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    [
      _c("p", { staticClass: "w-2/3 text-base section-header" }, [
        _vm._v(
          " Have you ever declined flying training or been disqualified/eliminated or DOR from a flying training course conducted by or for any military service? "
        ),
      ]),
      _c(
        "div",
        { staticClass: "my-4 form-input-container" },
        [
          _c("radio-component", {
            attrs: {
              id: _vm.FieldItemLabels.FLYING_EXPERIENCE_DROPPED_ON_REQUEST.key,
              "data-cy": "flyExperienceDorSelector",
              errorText: "Please indicate if applicant DOR",
              radioItems: _vm.yesNoRadioOptions,
            },
            on: {
              validationState: function ($event) {
                return _vm.$emit("validationState", $event)
              },
            },
            model: {
              value: _vm.application.flyExperienceDroppedonrequest,
              callback: function ($$v) {
                _vm.$set(_vm.application, "flyExperienceDroppedonrequest", $$v)
              },
              expression: "application.flyExperienceDroppedonrequest",
            },
          }),
        ],
        1
      ),
      _c("text-area-component", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.application.flyExperienceDroppedonrequest,
            expression: "application.flyExperienceDroppedonrequest",
          },
        ],
        attrs: {
          id: _vm.FieldItemLabels.FLYING_EXPERIENCE_DROPPED_ON_EXPLANATION.key,
          "data-cy": "flyExperienceDorExplanationInput",
          label: "If yes, please explain your answer.",
        },
        model: {
          value: _vm.application.flyExperienceDroppedonrequestexplanation,
          callback: function ($$v) {
            _vm.$set(
              _vm.application,
              "flyExperienceDroppedonrequestexplanation",
              $$v
            )
          },
          expression: "application.flyExperienceDroppedonrequestexplanation",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { attrs: { id: _vm.id } }, [
    !_vm.hideLabel
      ? _c("label", { staticClass: "block", attrs: { for: _vm._uid } }, [
          _vm._v(" " + _vm._s(_vm.label) + " "),
        ])
      : _vm._e(),
    _c("input", {
      ref: "fileInput",
      staticClass: "hidden",
      attrs: {
        "data-cy": "fileUploadInput",
        type: "file",
        multiple: _vm.multiple,
        name: _vm.uploadFieldName,
        disabled: _vm.readonly,
      },
      on: {
        blur: _vm.onBlur,
        change: function ($event) {
          return _vm.updateValue($event.target.files)
        },
      },
    }),
    _vm.isLoading
      ? _c(
          "div",
          {
            staticClass:
              "relative flex items-center w-full px-3 py-2 text-sm rounded shadow appearance-none focus:outline-none focus:shadow-outline",
            class: _vm.classes,
          },
          [
            _c("icon-component", {
              staticClass: "absolute stroke-0 animate-spin",
              attrs: { name: "loading", width: 20, height: 20 },
            }),
            _c("span", { staticClass: "ml-7" }, [_vm._v("Uploading")]),
          ],
          1
        )
      : !_vm.value && !_vm.isLoading
      ? _c(
          "button",
          {
            staticClass:
              "relative flex items-center w-full px-3 py-2 text-sm rounded appearance-none focus:outline-none focus:shadow-outline",
            class: [
              { "border-red-500": _vm.showError, classes: _vm.classes },
              _vm.classes,
            ],
            attrs: { type: "button", disabled: _vm.isSaving },
            on: {
              click: function ($event) {
                _vm.$refs.fileInput.click()
                _vm.isPristine = false
              },
              blur: _vm.onBlur,
            },
          },
          [
            _c("icon-component", {
              staticClass: "absolute fill-current stroke-0",
              attrs: { name: "upload", height: 24, width: 24 },
            }),
            _c("span", { staticClass: "ml-7" }, [
              _vm._v(_vm._s(_vm.uploadButtonText)),
            ]),
          ],
          1
        )
      : _c(
          "div",
          {
            staticClass:
              "flex flex-row flex-no-wrap w-full px-3 py-2 rounded appearance-none flex-space-between focus:outline-none focus:shadow-outline",
            class: _vm.classes,
          },
          [
            _c(
              "button",
              {
                staticClass:
                  "relative flex items-center flex-grow text-sm truncate appearance-none",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.downloadObject.apply(null, arguments)
                  },
                },
              },
              [
                _c("icon-component", {
                  staticClass: "absolute fill-current stroke-0",
                  attrs: { name: "download", height: 24, width: 24 },
                }),
                _c("span", { staticClass: "ml-7" }, [
                  _vm._v(_vm._s(_vm.downloadButtonText)),
                ]),
              ],
              1
            ),
            _c(
              "button",
              {
                staticClass:
                  "flex-grow-0 ml-2 text-white appearance-none hover:shadow-outline",
                attrs: { type: "button" },
                on: { click: _vm.clearFile },
              },
              [
                _c("icon-component", {
                  staticClass: "stroke-current fill-none",
                  attrs: { name: "trash", width: 14, height: 14 },
                }),
              ],
              1
            ),
          ]
        ),
    _vm.showError
      ? _c("div", { staticClass: "mt-2 text-xs leading-tight text-red-500" }, [
          _vm._v(" " + _vm._s(_vm.errorText) + " "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
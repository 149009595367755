var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", { attrs: { id: _vm.SectionLabels.TESTING_DATA.key } }, [
    _c("h3", { staticClass: "section-header" }, [
      _vm._v("Testing Information"),
    ]),
    _c(
      "div",
      { staticClass: "mb-10 form-input-container gap-y-5" },
      [
        _c("date-selector-component", {
          attrs: {
            id: _vm.FieldItemLabels.TESTING_AFOQT_DATE.key,
            "data-cy": "testingAfoqtDateSelector",
            errorText: "Please select a testing date",
            label: "Date",
          },
          on: {
            validationState: function ($event) {
              return _vm.$emit("validationState", $event)
            },
          },
          model: {
            value: _vm.application.testingInformationAfoqtdate,
            callback: function ($$v) {
              _vm.$set(_vm.application, "testingInformationAfoqtdate", $$v)
            },
            expression: "application.testingInformationAfoqtdate",
          },
        }),
        _c("text-input-component", {
          attrs: {
            id: _vm.FieldItemLabels.TESTING_PILOT_SCORE.key,
            "data-cy": "testingPilotInput",
            type: "number",
            maxlength: "3",
            errorText: "Please enter a pilot score (0-100).",
            label: "Pilot",
            regex: _vm.Regex.pftTest,
            placeholder: "1-100",
          },
          on: {
            validationState: function ($event) {
              return _vm.$emit("validationState", $event)
            },
          },
          model: {
            value: _vm.application.testingInformationPilotscore,
            callback: function ($$v) {
              _vm.$set(_vm.application, "testingInformationPilotscore", $$v)
            },
            expression: "application.testingInformationPilotscore",
          },
        }),
        _c("text-input-component", {
          attrs: {
            id: _vm.FieldItemLabels.TESTING_CSO_SCORE.key,
            "data-cy": "testingCSOInput",
            type: "number",
            maxlength: "3",
            errorText: "Please enter a CSO score (0-100).",
            label: "CSO",
            regex: _vm.Regex.pftTest,
            placeholder: "1-100",
          },
          on: {
            validationState: function ($event) {
              return _vm.$emit("validationState", $event)
            },
          },
          model: {
            value: _vm.application.testingInformationCsoscore,
            callback: function ($$v) {
              _vm.$set(_vm.application, "testingInformationCsoscore", $$v)
            },
            expression: "application.testingInformationCsoscore",
          },
        }),
        _c("text-input-component", {
          attrs: {
            id: _vm.FieldItemLabels.TESTING_ABM_SCORE.key,
            "data-cy": "testingABMInput",
            type: "number",
            maxlength: "3",
            errorText: "Please enter a ABM score (0-100).",
            label: "ABM",
            regex: _vm.Regex.pftTest,
            placeholder: "1-100",
          },
          on: {
            validationState: function ($event) {
              return _vm.$emit("validationState", $event)
            },
          },
          model: {
            value: _vm.application.testingInformationAbmscore,
            callback: function ($$v) {
              _vm.$set(_vm.application, "testingInformationAbmscore", $$v)
            },
            expression: "application.testingInformationAbmscore",
          },
        }),
        _c("text-input-component", {
          attrs: {
            id: _vm.FieldItemLabels.TESTING_AA_SCORE.key,
            "data-cy": "testingAAInput",
            type: "number",
            maxlength: "3",
            errorText: "Please enter a AA score (0-100).",
            label: "AA",
            regex: _vm.Regex.pftTest,
            placeholder: "1-100",
          },
          on: {
            validationState: function ($event) {
              return _vm.$emit("validationState", $event)
            },
          },
          model: {
            value: _vm.application.testingInformationAascore,
            callback: function ($$v) {
              _vm.$set(_vm.application, "testingInformationAascore", $$v)
            },
            expression: "application.testingInformationAascore",
          },
        }),
        _c("text-input-component", {
          attrs: {
            id: _vm.FieldItemLabels.TESTING_VERBAL_SCORE.key,
            "data-cy": "testingVerbalInput",
            type: "number",
            maxlength: "3",
            errorText: "Please enter a verbal score (0-100).",
            label: "Verbal",
            regex: _vm.Regex.pftTest,
            placeholder: "1-100",
          },
          on: {
            validationState: function ($event) {
              return _vm.$emit("validationState", $event)
            },
          },
          model: {
            value: _vm.application.testingInformationVerbalscore,
            callback: function ($$v) {
              _vm.$set(_vm.application, "testingInformationVerbalscore", $$v)
            },
            expression: "application.testingInformationVerbalscore",
          },
        }),
        _c("text-input-component", {
          attrs: {
            id: _vm.FieldItemLabels.TESTING_QUANTITATIVE_SCORE.key,
            "data-cy": "testingQuantitativeInput",
            type: "number",
            maxlength: "3",
            errorText: "Please enter a quantitative score (0-100).",
            label: "Quantitative",
            regex: _vm.Regex.pftTest,
            placeholder: "1-100",
          },
          on: {
            validationState: function ($event) {
              return _vm.$emit("validationState", $event)
            },
          },
          model: {
            value: _vm.application.testingInformationQuantitativescore,
            callback: function ($$v) {
              _vm.$set(
                _vm.application,
                "testingInformationQuantitativescore",
                $$v
              )
            },
            expression: "application.testingInformationQuantitativescore",
          },
        }),
        _c("text-input-component", {
          attrs: {
            id: _vm.FieldItemLabels.TESTING_PCSM_SCORE.key,
            "data-cy": "testingPcsmInput",
            type: "number",
            maxlength: "3",
            errorText: "Please enter a PCSM score (0-99).",
            label: "Flying Skill Metric (PCSM)",
            regex: _vm.Regex.pcsmTest,
            placeholder: "1-100",
          },
          on: {
            validationState: function ($event) {
              return _vm.$emit("validationState", $event)
            },
          },
          model: {
            value: _vm.application.testingInformationPcsmscore,
            callback: function ($$v) {
              _vm.$set(_vm.application, "testingInformationPcsmscore", $$v)
            },
            expression: "application.testingInformationPcsmscore",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
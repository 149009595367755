var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "form",
    {
      staticClass: "w-full px-2 lg:px-4",
      on: {
        submit: function ($event) {
          $event.preventDefault()
        },
      },
    },
    [
      _c("board-selection", {
        staticClass: "p-4",
        attrs: { applicant: _vm.applicant, boards: _vm.boards },
        model: {
          value: _vm.application,
          callback: function ($$v) {
            _vm.application = $$v
          },
          expression: "application",
        },
      }),
      _c("applicant-data", {
        staticClass: "p-4",
        attrs: { applicant: _vm.applicant },
        model: {
          value: _vm.application,
          callback: function ($$v) {
            _vm.application = $$v
          },
          expression: "application",
        },
      }),
      _c("prior-service", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.application.isPriorService,
            expression: "application.isPriorService",
          },
        ],
        staticClass: "p-4",
        attrs: { applicant: _vm.applicant },
        model: {
          value: _vm.application,
          callback: function ($$v) {
            _vm.application = $$v
          },
          expression: "application",
        },
      }),
      _c("physical-assessment", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.application.requirePhysicalFitness,
            expression: "application.requirePhysicalFitness",
          },
        ],
        staticClass: "p-4",
        attrs: { applicant: _vm.applicant },
        model: {
          value: _vm.application,
          callback: function ($$v) {
            _vm.application = $$v
          },
          expression: "application",
        },
      }),
      _c("academic-data", {
        staticClass: "p-4",
        attrs: { applicant: _vm.applicant },
        model: {
          value: _vm.application,
          callback: function ($$v) {
            _vm.application = $$v
          },
          expression: "application",
        },
      }),
      _c("training-preferences", {
        staticClass: "p-4",
        attrs: { applicant: _vm.applicant },
        model: {
          value: _vm.application,
          callback: function ($$v) {
            _vm.application = $$v
          },
          expression: "application",
        },
      }),
      _c("base-preferences", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.application.requirePreferredUptBases,
            expression: "application.requirePreferredUptBases",
          },
        ],
        staticClass: "p-4",
        attrs: { applicant: _vm.applicant },
        model: {
          value: _vm.application,
          callback: function ($$v) {
            _vm.application = $$v
          },
          expression: "application",
        },
      }),
      _c("flying-experience", {
        staticClass: "p-4",
        attrs: { applicant: _vm.applicant },
        model: {
          value: _vm.application,
          callback: function ($$v) {
            _vm.application = $$v
          },
          expression: "application",
        },
      }),
      _c("dropped-on-request", {
        staticClass: "p-4",
        attrs: { applicant: _vm.applicant },
        model: {
          value: _vm.application,
          callback: function ($$v) {
            _vm.application = $$v
          },
          expression: "application",
        },
      }),
      _c("exception-to-policy", {
        staticClass: "p-4",
        attrs: { applicant: _vm.applicant },
        model: {
          value: _vm.application,
          callback: function ($$v) {
            _vm.application = $$v
          },
          expression: "application",
        },
      }),
      _c("applicant-remarks", {
        staticClass: "p-4",
        attrs: { applicant: _vm.applicant },
        model: {
          value: _vm.application,
          callback: function ($$v) {
            _vm.application = $$v
          },
          expression: "application",
        },
      }),
      _c("endorsements", {
        staticClass: "p-4",
        attrs: { applicant: _vm.applicant },
        model: {
          value: _vm.application,
          callback: function ($$v) {
            _vm.application = $$v
          },
          expression: "application",
        },
      }),
      _c("testing-data", {
        staticClass: "p-4",
        attrs: { applicant: _vm.applicant },
        model: {
          value: _vm.application,
          callback: function ($$v) {
            _vm.application = $$v
          },
          expression: "application",
        },
      }),
      _c(
        "sticky-container-component",
        [
          _c("application-form-state-component", {
            attrs: {
              boards: _vm.boards,
              application: _vm.application,
              isUpdatingApplication: _vm.isUpdatingApplication,
            },
            on: { update: _vm.update },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
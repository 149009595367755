
import { Component, Mixins } from 'vue-property-decorator';
import TextAreaComponent from '@/components/Forms/Elements/TextAreaComponent.vue';
import ApplicationSectionMixin from '@/mixins/ApplicationSectionMixin';

@Component<ApplicationFormApplicantRemarksSection>({
  components: {
    TextAreaComponent
  }
})
export default class ApplicationFormApplicantRemarksSection extends Mixins(
  ApplicationSectionMixin
) {}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    { attrs: { id: _vm.SectionLabels.PHYSICAL_ASSESSMENT.key } },
    [
      _c("h3", [_vm._v("Physical Assessment")]),
      _c(
        "div",
        { staticClass: "form-input-container" },
        [
          _c("text-input-component", {
            attrs: {
              id: _vm.FieldItemLabels.PHYSICAL_ASSESSMENT_SCORE.key,
              label: _vm.FieldItemLabels.PHYSICAL_ASSESSMENT_SCORE.label,
              type: "number",
              "data-cy": "physicalAssessmentScoreInput",
              errorText: "Please select a fitness score (75-100)",
              regex: _vm.Regex.fitnessAssessment,
            },
            on: {
              validationState: function ($event) {
                return _vm.$emit("validationState", $event)
              },
            },
            model: {
              value: _vm.application.physicalAssessmentsScore,
              callback: function ($$v) {
                _vm.$set(_vm.application, "physicalAssessmentsScore", $$v)
              },
              expression: "application.physicalAssessmentsScore",
            },
          }),
          _c("date-selector-component", {
            attrs: {
              id: _vm.FieldItemLabels.PHYSICAL_ASSESSMENT_DATE.key,
              label: _vm.FieldItemLabels.PHYSICAL_ASSESSMENT_DATE.label,
              "data-cy": "physicalAssessmentDateSelector",
              errorText: "Please select an assessment date",
            },
            on: {
              validationState: function ($event) {
                return _vm.$emit("validationState", $event)
              },
            },
            model: {
              value: _vm.application.physicalAssessmentsDate,
              callback: function ($$v) {
                _vm.$set(_vm.application, "physicalAssessmentsDate", $$v)
              },
              expression: "application.physicalAssessmentsDate",
            },
          }),
          _c("file-upload-component", {
            attrs: {
              id: _vm.FieldItemLabels.PHYSICAL_ASSESSMENT_DOCUMENT.key,
              label: _vm.FieldItemLabels.PHYSICAL_ASSESSMENT_DOCUMENT.label,
              "data-cy": "physicalFitnessDocumentLocationFileUpload",
              bg: "blue",
              color: "white",
              border: false,
              errorText: "Please upload a Fitness Test File",
              uploadFieldName: "FitnessTestFileUpload",
            },
            model: {
              value:
                _vm.application
                  .physicalAssessmentsPhysicalfitnessdocumentlocation,
              callback: function ($$v) {
                _vm.$set(
                  _vm.application,
                  "physicalAssessmentsPhysicalfitnessdocumentlocation",
                  $$v
                )
              },
              expression:
                "application.physicalAssessmentsPhysicalfitnessdocumentlocation",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
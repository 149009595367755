var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    { attrs: { id: _vm.SectionLabels.EXCEPTION_TO_POLICY.key } },
    [
      _c("h3", { staticClass: "section-header" }, [
        _vm._v("Exception to policy (ETP)"),
      ]),
      _c(
        "div",
        [
          _c("checkbox-component", {
            attrs: {
              "data-cy": "etpSelector",
              isBinary: false,
              checkboxItems: _vm.exceptionToPolicyCheckboxOptions,
              errorText: "Please indicate the exception to policy",
            },
            on: {
              validationState: function ($event) {
                return _vm.$emit("validationState", $event)
              },
            },
            model: {
              value: _vm.application.exceptionToPolicyReasons,
              callback: function ($$v) {
                _vm.$set(_vm.application, "exceptionToPolicyReasons", $$v)
              },
              expression: "application.exceptionToPolicyReasons",
            },
          }),
          _c(
            "div",
            { staticClass: "form-input-container" },
            [
              _c("text-area-component", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showTextArea,
                    expression: "showTextArea",
                  },
                ],
                staticClass: "lg:col-span-2",
                attrs: {
                  "data-cy": "etpOtherInput",
                  small: true,
                  label: "Enter other",
                },
                on: {
                  validationState: function ($event) {
                    return _vm.$emit("validationState", $event)
                  },
                },
                model: {
                  value: _vm.application.exceptionToPolicyCustomreason,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.application,
                      "exceptionToPolicyCustomreason",
                      $$v
                    )
                  },
                  expression: "application.exceptionToPolicyCustomreason",
                },
              }),
              _c("file-upload-component", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.etpMemoRequired,
                    expression: "etpMemoRequired",
                  },
                ],
                attrs: {
                  id: _vm.FieldItemLabels.EXCEPTION_TO_POLICY_MEMO.key,
                  label: _vm.FieldItemLabels.EXCEPTION_TO_POLICY_MEMO.label,
                  bg: "blue",
                  color: "white",
                  "data-cy": "etpMemoLocationFileUpload",
                  errorText: "Please upload a ETP Memo",
                  uploadFieldName: "ETPMemoUpload",
                },
                model: {
                  value: _vm.application.exceptionToPolicyMemolocation,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.application,
                      "exceptionToPolicyMemolocation",
                      $$v
                    )
                  },
                  expression: "application.exceptionToPolicyMemolocation",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }

import { Component, Mixins } from 'vue-property-decorator';
import TextInputComponent from '@/components/Forms/Elements/TextInputComponent.vue';
import DropdownComponent from '@/components/Forms/Elements/DropdownComponent.vue';
import DateSelectorComponent from '@/components/Forms/Elements/DateSelectorComponent.vue';
import RadioComponent from '@/components/Forms/Elements/RadioComponent.vue';
import FileUploadComponent from '@/components/Forms/Elements/FileUploadComponent.vue';
import {
  ECivilianFlightRatingCategory,
  ECivilianFlightRatingClass,
  ECivilianPilotCertificate,
  EFlightCFITypes
} from '@/enums';
import { getInputOptions } from '@/util/getInputOptions';
import ApplicationSectionMixin from '@/mixins/ApplicationSectionMixin';

@Component<ApplicationFormFlyingExperienceSection>({
  components: {
    DateSelectorComponent,
    DropdownComponent,
    FileUploadComponent,
    RadioComponent,
    TextInputComponent
  }
})
export default class ApplicationFormFlyingExperienceSection extends Mixins(
  ApplicationSectionMixin
) {
  civilianPilotCertificateDropdownOptions = getInputOptions(
    ECivilianPilotCertificate
  );
  civilianFlightRatingCategoryDropdownOptions = getInputOptions(
    ECivilianFlightRatingCategory
  );
  civilianFlightRatingClassDropdownOptions = getInputOptions(
    ECivilianFlightRatingClass
  );
  civilianFlightCFIDropdownOptions = getInputOptions(EFlightCFITypes);
}

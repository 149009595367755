var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("application-form", {
        key: _vm.application.id,
        attrs: {
          applicant: _vm.applicant,
          application: _vm.model,
          boards: _vm.boards,
          currentBoard: _vm.currentBoard,
          isUpdatingApplication: _vm.isUpdatingApplication,
        },
        on: { update: _vm.updateApplication },
      }),
      _c("caution-modal-component", {
        ref: "cautionModalComponent",
        attrs: {
          icon: ["fas", "triangle-exclamation"],
          iconColor: "red",
          title: "Warning",
          continueText: "Delete",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }